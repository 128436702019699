@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap);

* {
  margin: 0px;
  padding: 0px;
  color: #000;
  font-family: 'Poppins', sans-serif;
}

html {
  font-size: 62.5%;
}

body {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
}

h1, h2, h3, h4, h5, h6, p, a, span {
  font-family: 'Poppins', sans-serif;
}

a, i {
  text-decoration: none;
}

ul {
  list-style: none;
}

p {
  font-size: 1.8rem;
  line-height: 1.5em;
  margin: 0 !important;
}

img {
  width: 100%;
}

.contentContainer {
  padding: 80px 110px;
}

@media only screen and (max-width: 550px) {
  .contentContainer {
    padding: 40px 20px;
  }
}