.headerContainer img {
    max-width: 180px;
}

.headerContainer p {
    color: #fff;
    font-size: 2.5rem;
}

.headerContainer {
    background-color: #000 !important;
}

.headerCol2 {
    justify-content: flex-end;
}

.headerlist {
    align-items: center;
    column-gap: 20px;
}

.headerInner {
    padding-right: 110px;
    padding-left: 110px;
}

@media only screen and (max-width: 550px) {
    .headerInner {
        padding-right: 20px;
        padding-left: 20px;
    }

    .headerContainer img {
        max-width: 115px;
    }
}