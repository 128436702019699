.sectionThreeCol2 h2 {
    font-size: 4.5rem;
    font-weight: 700;
}

.sectionThreeCol2 p {
    font-size: 2rem;
    color: #757575;
    margin-top: 20px !important;
}

.sectionThreeContainer {
    padding-top: 100px;
    padding-bottom: 100px;
}

.sectionThreeCol1 {
    text-align: center;
}

.sectionThreeCol1 img {
    max-width: 634px;
}

.pointer {
    font-size: 2.2rem;
    display: flex;
    align-items: flex-start;
}

.pointer div {
    color: #757575;
}

.pointer svg {
    fill: #757575;
    font-size: 2.2rem;
    margin-right: 10px;
}

.sectionThreeList {
    margin-top: 20px;
}

.sectionThreeCol2 button {
    background: none;
    border: none;
    color: #EBC77C;
    font-weight: 600;
    font-size: 2.5rem;
    width: 100%;
    margin-top: 20px;
}

@media only screen and (max-width: 550px) {
    .sectionThreeCol2 h2 {
        font-size: 3.5rem;
    }

    .sectionThreeCol1 {
        margin-bottom: 30px;
    }

    .sectionThreeContainer {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }
}