.footerContainer {
  background-image: url(../../assets/footerBg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.footerRow2Col p {
  margin-top: 10px !important;
}

.footerRow2Col h5,
.footerRow2Col p {
  color: #fff;
  font-size: 1.4rem;
}

.footerRow1Col1 h3 {
  color: #ebc77c;
  font-weight: 700;
  font-size: 3rem;
  margin-bottom: 60px;
}

.contactList {
  position: relative;
  padding-bottom: 40px;
}

.contactList:after {
  content: "";
  background: #fff;
  width: 420px;
  height: 2px;
  bottom: 0px;
  position: absolute;
}

.footerRow1 {
  margin-bottom: 60px;
}

.contactPointer {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.contactPointer svg {
  fill: #fff;
  background-color: #ebc77c;
  font-size: 5rem;
  padding: 15px;
  border-radius: 50%;
}

.contactPointer div a {
  color: #fff;
  font-size: 1.8rem;
  text-decoration: none;
}

.contactPointer div {
  color: #fff;
  font-size: 1.8rem;
}

.addressList {
  padding-top: 40px;
}

.contactForm form input,
.contactForm form select,
.contactForm form button {
  width: 100%;
}

.contactForm form input,
.contactForm form select {
  background: #f7f7f7;
  border-radius: 50px;
  height: 54px;
  margin-bottom: 20px;
  padding: 0 15px;
  font-size: 1.6rem;
}

.contactForm form input::placeholder,
.contactForm form select {
    font-size: 1.6rem;
}

.contactForm form {
    max-width: 60%;
}

.contactForm form button {
    max-width: 45%;
    font-size: 1.8rem;
    background: #EBC77C;
    border-radius: 50px;
    height: 54px;
    font-weight: 600;
}

.submit {
    text-align: center;
}

@media only screen and (max-width: 550px) {
    .contactForm form {
        max-width: 100%;
        margin-top: 30px;
    }
}

.socialLinks {
  display: flex;
  justify-content: center;
  column-gap: 20px;
  max-width: 60%;
  margin-top: 30px;
  align-items: center;
}

.socialLinks svg {
  fill: #fff;
  font-size: 3rem;
}
