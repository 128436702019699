.sectionOneCol1 {
    background-color: #F7F7F7;
    padding: 80px 40px 80px 110px;
}

.sectionOneCol2 {
    background-color: #EBEBEB;
    padding: 80px 110px 80px 80px;
}

.sectionOneCol1 h2 {
    font-size: 5.7rem;
    font-weight: 700;
}

.sectionOneCol1 p {
    font-size: 2rem;
    color: #757575;
    margin-top: 10px !important;
}

.sectionOneCol2 h2 {
    color: #FFAD00;
    font-size: 4rem;
    font-weight: 700;
    margin-top: 40px;
}

.sectionOneCol2 p {
    color: #757575;
    font-size: 2rem;
}

/* .sectionOneCol2Img {
    text-align: center;
} */

.sectionOneCol2Img img {
    max-width: 690px;
}

@media only screen and (max-width: 550px) {
    .sectionOneCol1, .sectionOneCol2 {
        padding: 40px 20px;
    }

    .sectionOneCol1 h2 {
        font-size: 4rem;
    }
}