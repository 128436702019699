.sectionTwoCol2 h2 {
    font-size: 4.5rem;
    font-weight: 700;
}

.sectionTwoCol2 p {
    font-size: 2rem;
    color: #757575;
    margin-top: 20px !important;
}

.sectionTwoContainer {
    padding-top: 100px;
    padding-bottom: 100px;
}

.sectionTwoCol1 {
    text-align: center;
}

.sectionTwoCol1 img {
    max-width: 634px;
}

@media only screen and (max-width: 550px) {
    .sectionTwoCol2 h2 {
        font-size: 3.5rem;
    }

    .sectionTwoCol1 {
        margin-bottom: 30px;
    }

    .sectionTwoContainer {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }
}