.bestButton {
  background-color: #ebc77c;
  border-radius: 50px;
  color: #000;
  border: 2px solid #ebc77c;
  font-size: 2.2rem;
  font-weight: 600;
  padding: 12px 30px;
}

.bestButton:hover {
    background-color: #ebc87c00;
    color: #ebc77c;
    border-color: #ebc77c;
}
