.reviewSectionCol1 h2 {
  font-weight: 700;
  font-size: 3.8rem;
}

.reviewSectionCol1 p {
  color: #757575;
  font-size: 2rem;
  margin-top: 10px !important;
}

.reviewSectionCol1 {
    align-self: center;
    padding-right: 90px;
}

.reviewCardContainer {
  background: #ffffff;
  box-shadow: 0px 20px 40px 10px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 40px;
  position: relative;
}

.quotesIcon img {
    max-width: 54px;
}

.reviewCardContainer p {
    color: #757575;
    font-size: 2rem;
    margin-top: 25px !important;
}

.reviewWrittenBy {
    margin-top: 40px;
}

.reviewWrittenBy h5 {
    font-size: 1.6rem;
    font-weight: 700;
}

.reviewWrittenBy p{
    color: #757575;
    font-size: 1.5rem;
    margin-top: 0 !important;
}

.reviewCircle {
    max-width: 120px;
    position: absolute;
    right: 0;
    bottom: 0;
}

.reviewSlider {
    padding: 20px;
}

@media only screen and (max-width: 550px) {
    .reviewSectionCol1 {
        padding: 0;
        margin-bottom: 30px;
    }
}
