.sectionFiveCol1 h2 {
    font-size: 4.5rem;
    font-weight: 700;
}

.sectionFiveCol1 p {
    font-size: 2rem;
    color: #757575;
    margin-top: 20px !important;
}

.sectionFiveCol1 a {
    margin-top: 20px;
}

.sectionFiveContainer {
    padding-top: 100px;
    padding-bottom: 100px;
}

.sectionFiveCol2 {
    text-align: center;
}

.sectionFiveCol2 img {
    max-width: 634px;
}

@media only screen and (max-width: 550px) {
    .sectionFiveCol1 h2 {
        font-size: 3.5rem;
    }

    .sectionFiveCol1 {
        margin-bottom: 30px;
    }

    .sectionFiveContainer {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }
}