.bannerContainer {
  background-image: url(../../assets/bannerBG.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 650px;
  padding: 0 110px;
  display: flex;
  align-items: center;
}

.bannerContainer h2 {
  font-size: 5.5rem;
  font-weight: 700;
}

.bannerContainer p {
    color: #757575;
    margin-top: 10px !important;
    font-size: 2.2rem;
}

.bannerContainer a {
    margin-top: 30px;
}

@media only screen and (max-width: 550px) {
  .bannerContainer {
    padding: 40px 20px !important;
    align-items: flex-start;
    background-size: 722px;
    min-height: 799px;
    background-position: 100% 100%;
    background-color: #fcf8e9;
  }

  .bannerContainer h2 {
    font-size: 4rem;
  }
}
